import React, {createContext, Dispatch, useCallback, useContext, useReducer} from 'react'
import {Action, reducer} from "../state/appReducer"
import {initialState, StateType} from "../state/appState"
import { useLocalStorage } from 'react-use'

const APP_STATE_KEY = 'APP_STATE_KEY'

interface ContextType {
  state: StateType
  dispatch: Dispatch<Action>
}

export function getStateFromLocalStorage() {
  return localStorage.getItem(APP_STATE_KEY)
}

const AppContext = createContext({} as ContextType)

export const useAppContext = () => useContext(AppContext)

const usePersistReducer = () => {
  const [savedState, saveState] = useLocalStorage(APP_STATE_KEY, initialState)
  const reducerLocalStorage = useCallback(
    (state: StateType, action: Action): StateType => {
      const newState = reducer(state, action)
      saveState(newState)
      return newState
    },
    [saveState]
  )
  return useReducer(reducerLocalStorage, savedState as StateType)
}


export default function AppContextProvider({children}: { children: any }) {
  const [state, dispatch] = usePersistReducer()

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
}
