import Box from '@mui/material/Box'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Button, TextField, Typography, useTheme} from '@mui/material'
import {otpApi} from "../../api/otp";
import {useState} from "react";


const validationSchema = yup.object({
  code: yup
    .string()
    .required('Введите одноразовый код')
    .max(10, 'Слишком длинный код')
})

export default function CodeForm(
  {
    msisdn,
    onSuccess,
    onReset
  }: {
    msisdn: string,
    onSuccess: (token: string) => void,
    onReset: () => void
  }
) {
  const [formError, setFormError] = useState<string>();

  const theme = useTheme()

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('submit code', values)
      setFormError(undefined)
      otpApi.verify(msisdn, values.code).then(
        response => {
          if (response.state === 'ok' && response.token) {
            onSuccess(response.token)
          } else if (response.state === 'error' && response.details) {
            if (response.details.code === 'code_invalid') {
              setFormError('Неправильный код')
            } else if (response.details.description) {
              setFormError(response.details.description)
            } else {
              setFormError('Произошла ошибка')
            }
          } else {
            setFormError('Произошла ошибка')
          }
        },
        error => {
          console.log('got error', error)
          setFormError('Произошла ошибка')
        }
      )
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
      <Box height={theme.typography.htmlFontSize * 1.5} sx={{mb: 1}}>
        {formError && <Typography color={theme.palette.error.main}>{formError}</Typography>}
      </Box>
      <TextField
        variant='filled'
        id='code'
        name='code'
        label='Проверочный код'
        value={formik.values.code}
        onChange={formik.handleChange}
        onFocus={() => formError && setFormError(undefined)}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
      />
      <Button sx={{mt: 2}} type='submit' variant='outlined'>
        Отправить
      </Button>
      <Button sx={{mt: 2}} onClick={onReset} variant='outlined'>
        Отмена
      </Button>
    </form>
  )
}
