import React, {useMemo} from 'react'
import './App.css'
import AppContextProvider from "./components/AppContextProvider"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import {useMediaQuery} from "@mui/material"
import {BrowserRouter} from "react-router-dom"
import AppRoutes from "./components/AppRoutes"
import customizedTheme from "./theme/customizedTheme"
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import 'moment/locale/ru'



function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(
    () => customizedTheme(prefersDarkMode),
    [prefersDarkMode],
  )
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppContextProvider>
          <BrowserRouter>
            <AppRoutes/>
          </BrowserRouter>
        </AppContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

