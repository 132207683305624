import createTheme from "@mui/material/styles/createTheme";

export default function lightTheme() {
  return createTheme({
    palette: {
      mode: 'light',
    },
  })
}

