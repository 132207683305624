import * as React from "react";
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    Paper,
    IconButton,
    Collapse,
    Box,
    Tabs,
    Tab,
    List,
    ListSubheader,
    ListItem,
    ListItemText
} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from "@mui/material/TablePagination";
import {useEffect, useState} from "react"
import {MkEntry, reportApi} from "../../api/report"
import {
    formatCurrency,
    formatDtHuman,
    parseDtExternal,
    isNumber,
    roundToNextHour,
    plusHours,
    formatDtInternal
} from "../../utils/format"
import {DateTimePicker} from "@mui/x-date-pickers"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoopIcon from '@mui/icons-material/Loop';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSnackbar } from 'notistack';
import logout from "../../handlers/auth/logout";
import {useAppContext} from "../AppContextProvider";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {v4 as uuidv4} from 'uuid';

const useTabStyles = makeStyles({
    root: {
        textTransform: 'none',
        "& .Mui-selected": {
            color: '#1976d2'
        },
        color: 'black',
    },
    indicator: {
        display: 'none'
    }
});

interface Column {
    id: 'ts' | 'type' | 'state' | 'amount' | 'fee';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: string | number) => string | JSX.Element;
}

const columns: readonly Column[] = [
    {id: 'state', label: 'Статус', align: 'center', format: (t) => {
            switch (t) {
                case "success": return (
                    <CheckCircleOutlineIcon color={'success'}/>
                )
                case "failed": return (
                    <ErrorOutlineIcon color={'error'}/>
                )
                case "processing": return (
                    <LoopIcon color={'disabled'}/>
                )
                default: return t as string;
            }
        }
    },
    {id: 'type', label: 'Тип', align: 'center', format: (t) => {
            switch (t) {
                case "m2c": return "Перевод на карту";
                case "m2m": return "Перевод на телефон";
                case "m2a": return "Перевод на р/с";

                case "pp": return "Обещанный платеж+";

                case "dp": return "Отложенный платеж+";

                case "dn":
                case "dns": return "Доначисление";

                case "pdp":
                case "pdps": return "Отложенный платеж";

                case "sp": return "Срочный платеж";

                case "t2a": return "Подарочные карты App Store & iTunes";

                default: return t as string;
            }
        }
    },
    {id: 'ts', label: 'Время', align: 'center', format: (value: string | number) => { return formatDtHuman(parseDtExternal(value as string)) }},
    {id: 'amount', label: 'Сумма', align: 'center', format: (value: string | number) => { return formatCurrency(value as number) }},
    {id: 'fee', label: 'Комиссия', align: 'center', format: (value: string | number) => { return formatCurrency(value as number || 0) }}
]

export default function TransactionsInfo() {
    const {dispatch} = useAppContext();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [msisdn, setMsisdn] = useState<string>('');
    const [msisdnError, setMsisdnError] = useState(true);
    const [msisdnErrorMessage, setMsisdnErrorMessage] = useState('');

    const [fromDate, setFromDate] = useState(roundToNextHour(plusHours(new Date(), -2)))
    const [fromDateError, setFromDateError] = useState(false);

    const [tillDate, setTillDate] = useState(roundToNextHour(new Date()))
    const [tillDateError, setTillDateError] = useState(false);

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<MkEntry[]>();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
    }, [])

    const handleLoadButtonClick = () => {
        loadData(msisdn);

    }

    function logoutIfUnauthorized(errorMsg: string | undefined) {
        if (errorMsg && errorMsg == 'Unauthorized') {
            logout(dispatch, navigate);

        }
    }

    //

    function loadData(msisdn: string) {
        setLoading(true);
        setData([] as MkEntry[]);

        reportApi.listMk(msisdn, formatDtInternal(fromDate), formatDtInternal(tillDate)).then(
            response => {
                if(response) {
                    setData(response);

                }
            },
            error => {
                logoutIfUnauthorized(error.message);

                console.log('listMk error', error);
                enqueueSnackbar('Ошибка загрузки транзакций: ' + error.message, { variant: 'error' });

            }
        )
            .then(() => {
                setLoading(false);

            })

    }

    //

    const handleMsisdnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newMsisdn = event.target.value;

        if(!isNumber(newMsisdn)) {
            setMsisdnError(true);
            setMsisdnErrorMessage('Номер телефона должен содержать только цифры');

        } else {
            setMsisdnError(false);
            setMsisdnErrorMessage('');

        }

        setMsisdn(newMsisdn);

    };

    const handleFromDateChange = (date: Date | null) => {
        if(date) {
            if(date > tillDate) {
                setFromDate(date);
                setTillDate(date);

            } else {
                setFromDate(date);

            }
        }
    };

    const handleFromDateError = (error: any) => {
        setFromDateError(error ? true : false);

    };

    const handleTillDateChange = (date: Date | null) => {
        if(date) {
            if(date < fromDate) {
                setFromDate(date);
                setTillDate(date);

            } else {
                setTillDate(date);

            }
        }
    };

    const handleTillDateError = (error: any) => {
        setTillDateError(error ? true : false);

    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (<Grid container spacing={2}>
        <Grid item>
            <TextField
                style={{ width: 334 }}
                size='small'
                error={msisdnError}
                helperText={msisdnErrorMessage}
                id="msisdn"
                label="Номер телефона"
                variant="outlined"
                value={msisdn}
                disabled={loading}
                onChange={handleMsisdnChange}
            />
        </Grid>
        <Grid item>
            <DateTimePicker
                disabled={loading || msisdnError}
                value={fromDate}
                onChange={handleFromDateChange}
                onError={handleFromDateError}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            style={{ width: 200 }}
                            size='small'
                            label='Дата с'
                        />
                }
            />
        </Grid>
        <Grid item>
            <DateTimePicker
                disabled={loading || msisdnError}
                value={tillDate}
                onChange={handleTillDateChange}
                onError={handleTillDateError}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            style={{ width: 200 }}
                            size='small'
                            label='Дата по'
                        />
                }
            />
        </Grid>
        <Grid item>
            <Button
                color='primary'
                disabled={loading || msisdnError || fromDateError || tillDateError}
                onClick={handleLoadButtonClick}>
                Загрузить
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{overflow: 'hidden'}}>
                <TableContainer sx={{maxHeight: '60vh'}}>
                    <Table stickyHeader aria-label="sms-info-table">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                {columns.map((column) => (
                                    <TableCell
                                        key={uuidv4()}
                                        align={column.align}
                                        sx={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { loading ?
                                (<TableRow sx={{ bgcolor: theme => theme.palette.action.hover }}>
                                    <TableCell colSpan={6} align={'center'}>
                                        <CircularProgress variant={loading ? "indeterminate" : "determinate"} value={0}/>
                                    </TableCell>
                                </TableRow>) :
                                !data || data.length === 0 ?
                                    (<TableRow sx={{ bgcolor: theme => theme.palette.action.hover }}>
                                        <TableCell colSpan={6} align={'center'}>Нет данных</TableCell>
                                    </TableRow>) :
                                    (rowsPerPage > 0
                                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : data
                                    ).map((row, index) =>
                                        <Row key={uuidv4()} row={row} index={index} logoutFunc={logoutIfUnauthorized} />
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'Все' }]}
                    component="div"
                    count={!data ? 0 : data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage='Строк на странице'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    </Grid>)
}

interface ObjTableHeader {
    name: string,
    columns: number
}

interface ObjTable {
    headers: ObjTableHeader[][],
    currentHeaderIndex: number,
    values: string[]
}

interface ObjTableNode {
    name: string | null,
    headers: string[],
    values: string[],
    list: ObjTableNode[]
}

function Row(props: { row: MkEntry, index: number, logoutFunc: Function }) {
    const { enqueueSnackbar } = useSnackbar();

    const { row, index, logoutFunc } = props;
    const [open, setOpen] = React.useState(false);
    const [detailsLoading, setDetailsLoading] = React.useState(false);
    //const [data, setData] = React.useState<ObjTable[]>();
    const [data, setData] = React.useState<ObjTableNode | null>();
    const [json, setJson] = React.useState<any>();

    const handleOpenButtonClick = () => {
        if(!detailsLoading) {
            if(open) {
                setOpen(!open);

            } else {
                loadData(row['service'], row['id']);

            }
        }
    }

    // Object to table model

    function isFieldExclude(service: string, key: string, obj: any) {
        let excludeFields = {
            t2t: {
                fields: [ 'paymentState', 'checkState', 'attempt' ]
            },
            t2a: {
                fields: [ '_class' ]
            }
        };

        if(service === 't2t') {
            if(excludeFields.t2t !== undefined) {
                if(excludeFields.t2t.fields.indexOf(key) >= 0) {
                    return true;

                } else if(key === 'ef' && obj.ef.check.state === 'unknown') {
                    return true;

                } else if(key === 'b131' && obj.b131.check.state === 'unknown') {
                    return true;

                } else if(key === 'teko' && obj.teko.check.state === 'unknown') {
                    return true;

                } else if(key === 'mb1' && obj.mb1.check.state === 'unknown') {
                    return true;

                }
            }
        } else if(service === 't2a') {
            if(excludeFields.t2a !== undefined && excludeFields.t2a.fields.indexOf(key) >= 0) {
                return true;

            }
        }

        return false;
    }

    function translateTableValue(key: string, value: string) {
        if(key === 'amount' || key === 'fee' || key == 'balance') {
            return formatCurrency(Number(value));

        } else if(key === 'created' || key === 'updated' || key === 'date' || key === 'ts' || key === 'last_update') {
            return formatDtHuman(parseDtExternal(value));

        }

        return value;
    }

    function translateTableText(value: string) {
        if(typeof value === 'boolean') {
            return new Boolean(value).toString();

        }

        if(value == '_id' || value == 'id') {
            return 'ID транзакции';

        } else if(value == 'ef') {
            return 'Элексир';

        } else if(value == 'mobi') {
            return 'МОБИ.Деньги';

        } else if(value == 'b131') {
            return 'Банк131';

        } else if(value == 'teko') {
            return 'Теко';

        } else if(value == 'mb1') {
            return 'МБ1';

        } else if(value == 'balance') {
            return 'Баланс';

        } else if(value == 'timeout') {
            return 'Истекло время ожидания';

        } else if(value == 'attempt') {
            return 'Попытки';

        } else if(value == 'bankIds') {
            return 'ID банков';

        } else if(value == 'inBanWhitelist') {
            return 'В белом списке на БАН';

        } else if(value == 'Balance insufficient') {
            return 'Недостаточный баланс';

        } else if(value == 'ok' || value == 'success') {
            return 'Успешно';

        } else if(value == 'error' || value == 'fail' || value == 'ko' || value == 'failed' || value == 'Payment failed') {
            return 'Ошибка';

        } else if(value == 'unknown') {
            return 'Невыполнено';

        } else if(value == 'amount') {
            return 'Сумма';

        } else if(value == 'fee') {
            return 'Комиссия';

        } else if(value == 'msisdn' || value == 'subscriber') {
            return 'Номер телефона';

        } else if(value == 'receiver') {
            return 'Получатель';

        } else if(value == 'state') {
            return 'Статус';

        } else if(value == 'created') {
            return 'Создано';

        } else if(value == 'updated') {
            return 'Обновлено';

        } else if(value == 'code') {
            return 'Код';

        } else if(value == 'description') {
            return 'Описание';

        } else if(value == 'externalId' || value == 'checkId') {
            return 'ID платежной системы';

        } else if(value == 'psys') {
            return 'Платежная система';

        } else if(value == 'accountId') {
            return 'ID аккаунта';

        } else if(value == 'certificateId') {
            return 'ID сертификата';

        } else if(value == 'check') {
            return 'Проверка возможности';

        } else if(value == 'payment') {
            return 'Платеж';

        }

        return value;
    }

    function getNonObjectFieldsCount(obj: any) {
        let count = 0;
        for(var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if(obj[key] === null ||
                    obj[key] === undefined ||
                    Array.isArray(obj[key]) ||
                    typeof obj[key] !== 'object' ||
                    typeof obj[key] !== 'function') {
                    count++;

                }
            }
        }

        return count;
    }

    function createTableModel(service: string, rootObj: any) {
        let result: ObjTable[] = [];

        let nestedObjects = [ rootObj ];
        let nextLevelNestedObjects = [];

        let table: ObjTable | null = null;
        let headerTable: ObjTable | null = null;

        while(nestedObjects.length > 0) {
            let obj = nestedObjects.shift();

            for(var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if(isFieldExclude(service, key, obj)) continue;

                    let val = obj[key];

                    if(table === null) {
                        table = { headers: [], values: [], currentHeaderIndex: 0 };
                        table.headers.push([]);

                    }

                    if(val === null || val === undefined) {
                        table.headers[table.currentHeaderIndex].push({ name: translateTableText(key), columns: 1 });
                        table.values.push('');

                    } else if(typeof val === 'object' && !Array.isArray(val)) {
                        if(headerTable === null) {
                            headerTable = { headers: [], values: [], currentHeaderIndex: 0 };
                            headerTable.headers.push([]);

                        }

                        headerTable.headers[headerTable.currentHeaderIndex].push(
                            { name: translateTableText(key), columns: val ? getNonObjectFieldsCount(val) : 1 }
                        );
                        nextLevelNestedObjects.push(val);

                    } else if(typeof val !== 'function') {
                        table.headers[table.currentHeaderIndex].push({ name: translateTableText(key), columns: 1 });

                        if(Array.isArray(val)) {
                            table.values.push('[' + val.toString() + ']');

                        } else {
                            table.values.push(
                                translateTableValue(
                                    key,
                                    translateTableText(val)
                                )
                            );
                        }
                    }
                }
            }

            if(table !== null && nestedObjects.length === 0) {
                result.push(table);
                table = headerTable;

                if(table !== null) {
                    table.headers.push([]);
                    table.currentHeaderIndex++;

                }

                if(headerTable !== null) {
                    headerTable = null;
                    nestedObjects = nextLevelNestedObjects;
                    nextLevelNestedObjects = [];

                }
            }
        }

        console.log("result: ", result);

        return result;
    }

    function createTableListModel(service: string, rootObj: any) {
        return createTableNodeModel(service, rootObj, null);

    }

    function createTableNodeModel(service: string, rootObj: any, objNode: ObjTableNode | null) {
        let result: ObjTableNode | null = objNode;

        for(var key in rootObj) {
            if (rootObj.hasOwnProperty(key)) {
                if(isFieldExclude(service, key, rootObj)) continue;

                if(result === null) {
                    result = { name: null, headers: [], values: [], list: []};
                }

                let val = rootObj[key];

                if(val === null || val === undefined) {
                    result.headers.push(translateTableText(key));
                    result.values.push('');

                } else if(typeof val === 'object' && !Array.isArray(val)) {
                    let listNode = { name: translateTableText(key), headers: [], values: [], list: []};

                    result?.list.push(listNode);

                    createTableNodeModel(service, val, listNode);
                    
                } else if(typeof val !== 'function') {
                    result.headers.push(translateTableText(key));

                    if(Array.isArray(val)) {
                        result.values.push('[' + val.toString() + ']');

                    } else {
                        result.values.push(
                            translateTableValue(
                                key,
                                translateTableText(val)
                            )
                        );
                    }
                }
            }
        }

        //console.log("result: ", result);

        return result;
    }

    //

    function loadData(service: string, id: string) {
        setDetailsLoading(true);

        reportApi.mkDetails(service, id).then(
            response => {
                if(response) {
                    console.log("mkDetails data: " + response);

                    setJson(response);
                    console.log('response.value', response)

                    // list table
                    setData(createTableListModel(service, response));

                    // table
                    //setData(createTableModel(service, response));

                }
            },
            error => {
                logoutFunc(error.message);

                console.log('mkDetails error', error);
                enqueueSnackbar('Ошибка загрузки детальной информации: ' + error.message, { variant: 'error' });

            }
        )
            .then(() => {
                setDetailsLoading(false);
                setOpen(true);

            })

    }

    return (
        <React.Fragment>
            <TableRow sx={ index % 2 ? { backgroundColor: "white" } : { bgcolor: theme => theme.palette.action.hover } }>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={handleOpenButtonClick}
                    >
                        {detailsLoading ?
                            <CircularProgress size={'1.5rem'} variant={detailsLoading ? "indeterminate" : "determinate"} value={0}/>
                            : open ?
                                <KeyboardArrowUpIcon /> :
                                <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
                {columns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={uuidv4()} align={column.align}>
                            {value ?
                                column.format ?
                                    column.format(value)
                                    : value
                                : ''}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow>
                <TableCell style={open ? { paddingBottom: 0, paddingTop: 0 } : { paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TabView labels={["JSON", "Таблицей"]}
                                 tabs={[
                                   <div>
                                       <pre>
                                           {JSON.stringify(json, null, 2)}
                                       </pre>
                                   </div>,
                                     <TxDetailsList data={data}/>
                                 ]}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function TabView({labels, tabs}: {labels: string[], tabs: React.ReactNode[]}) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
              {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
              )}
          </div>
        );
    }

    return (
      <>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
                  labels.map((label, idx) => <Tab label={label} {...a11yProps(idx)} />)
              }
          </Tabs>

          {
              tabs.map((tab, idx) => <TabPanel value={value} index={idx}>{tab}</TabPanel>)
          }
      </>
      )

}

function TxDetailsList(props: { data: ObjTableNode | null | undefined }) {
    const { data } = props;

    function chunk<String>(array: string[], chunkSize: number): string[][] {
        const result = [];

        for (let i = 0, len = array.length; i < len; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));

        }

        return result;
    }

    function renderTable(node: any, vertical: boolean) {
        let chunkSize = 8;

        if(node) {
            console.log("node: ", node);

        }

        if(vertical) {
            // Vertical table
            return (
                <>
                    {node.headers && node.headers.length > 0 &&
                        <Grid container spacing={2}>
                            {chunk(node.headers, chunkSize).map((headers: string[], chunkIndex) => (
                                <Grid item>
                                    <ListItem key={uuidv4()}>
                                        <Table>
                                            <TableBody>
                                                {headers.map((header: string, headerIndex: number) => (
                                                    <TableRow
                                                        key={uuidv4()}
                                                        sx={{ bgcolor: theme => theme.palette.action.hover }}>
                                                        <TableCell
                                                            variant="head"
                                                            key={uuidv4()}
                                                            sx={{ textAlign: 'left' }}>
                                                            {header}
                                                        </TableCell>
                                                        <TableCell
                                                            variant="body"
                                                            key={uuidv4()}
                                                            sx={{ textAlign: 'left' }}>
                                                            {chunk(node.values, chunkSize)[chunkIndex][headerIndex]}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </ListItem>
                                </Grid>
                            ))
                            }
                        </Grid>
                    }
                </>
            );
        } else {
            // Horizontal table
            return (
                <>
                    {node.headers && node.headers.length > 0 &&
                        chunk(node.headers, 8).map((headers: string[], index) => (
                            <ListItem key={uuidv4()}>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            key={uuidv4()}
                                            sx={{ bgcolor: theme => theme.palette.action.hover }}>
                                            {headers.map((header: string) => (
                                                <TableCell
                                                    key={uuidv4()}
                                                    sx={{ textAlign: 'center' }}>
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {chunk(node.values, 8)[index].map((value: string) => (
                                                <TableCell key={uuidv4()} sx={{ textAlign: 'center' }}>
                                                    {value}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </ListItem>
                        ))
                    }
                </>
            );
        }
    }

    function renderNode(listNode: any) {
        return (
            <>
                {listNode.name ?
                    (<ul>
                        {listNode.name &&
                            <ListItem key={uuidv4()}>
                                <Typography variant="body1" gutterBottom component="div">
                                    {listNode.name}
                                </Typography>
                            </ListItem>
                        }

                        {renderTable(listNode, true)}

                        {listNode.list &&
                            listNode.list.map((subNode: any) => (
                                renderNode(subNode)
                            ))
                        }
                    </ul>) :
                    (
                        <>
                            {renderTable(listNode, true)}

                            {listNode.list &&
                                listNode.list.map((subNode: any) => (
                                    renderNode(subNode)
                                ))
                            }
                        </>
                    )
                }
            </>
        );
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item //sx={{ width: '100%' }}
                >
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h5" gutterBottom component="div">
                            Детальная информация
                        </Typography>
                    </Box>
                    <List
                        //sx={{width: '100%', bgcolor: 'background.paper'}}
                        //sx={{width: '100%', bgcolor: 'background.paper'}}
                        component="nav"
                        aria-labelledby="details-list"
                    >
                        {data && (
                            <>
                                {renderNode(data)}
                            </>
                        )}
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

function TxDetailsTable(props: { data: any }) {
    const { data } = props;

    return (
        <React.Fragment>
            <Box sx={{ margin: 1 }}>
                <Typography variant="h5" gutterBottom component="div">
                    Детальная информация
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item sx={{ width: '100%' }}>
                    {data &&
                        data.map((table: ObjTable) => (
                            <Box
                                key={uuidv4()}
                                sx={{ marginBottom: table.values.length > 0 ? '20px' : '0' }}>
                                <Table>
                                    <TableHead>
                                        {table.headers.map((header: ObjTableHeader[], index) => (
                                            <TableRow
                                                key={uuidv4()}
                                                sx={{ bgcolor: theme => theme.palette.action.hover }}>
                                                {table.headers[index].map((header: ObjTableHeader, index) => (
                                                    <TableCell
                                                        key={uuidv4()}
                                                        colSpan={header.columns}
                                                        sx={{ textAlign: 'center' }}>
                                                        {header.name}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {table.values.map((value: string) => (
                                                <TableCell key={uuidv4()} sx={{ textAlign: 'center' }}>
                                                    {value}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        ))
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}