/////
/////   OtpInit
/////

import {apiPost} from "./common"

export type OtpInitResponse = {
  state: OtpResponseState
  details?: OtpInitResponseDetails
}

export type OtpResponseState = "ok" | "error"

export type OtpInitResponseDetails = {
  retryAfter: number
  code?: string
  description?: string
}


/////
/////   OtpVerify
/////

export type OtpVerifyResponse = {
  state: OtpResponseState
  token?: string
  details?: OtpVerifyResponseDetails
}

export type OtpVerifyResponseDetails = {
  code: string
  description: string
}


/////
/////   Token
/////

export type CheckTokenResponse = {
  version: string,
  rev: string,
  builtAt: string
}

class OtpApi {

  async init(msisdn: string): Promise<OtpInitResponse> {
    console.log('OtpApi.init', msisdn)
    //return apiPost(`/otp/init?msisdn=${msisdn}`)
    return apiPost(`/otp/init?msisdn=${msisdn}`)
  }

  async verify(msisdn: string, code: string): Promise<OtpVerifyResponse> {
    console.log('OtpApi.verify', msisdn, code)
    //return apiPost(`/otp/verify?msisdn=${msisdn}&code=${code}`)
    return apiPost(`/otp/verify?msisdn=${msisdn}&code=${code}`)
  }

  async checkToken(): Promise<boolean> {
    return apiPost(`/auth/check`).then(() => true).catch(() => false)
  }

  async getVersion(): Promise<CheckTokenResponse> {
    return apiPost(`/auth/check`)
  }
}

export const otpApi = new OtpApi()

