//const protocol = window.location.protocol.substring(0, window.location.protocol.length - 1);
//const ApiRoot = `${protocol}://${window.location.hostname}/api`;

// Return `value` after a random delay.
// For testing purposes only
// export function delayed<T>(value: T, timeout = 100): Promise<T> {
//     timeout *= Math.random() + 1
//     return new Promise(resolve => setTimeout(() => resolve(value), timeout))
// }


import {getStateFromLocalStorage} from "../components/AppContextProvider";

//const ApiRoot = "https://api.sibinco.ru/t2sh";

const protocol = window.location.protocol.substring(0, window.location.protocol.length - 1);
export const ApiRoot = `${protocol}://${window.location.hostname}/api`;

function getToken(): string | undefined {
  const stateString = getStateFromLocalStorage()
  if (stateString) {
    const state = JSON.parse(stateString)
    return state.token as string
  }
}

function onResponse<T>(resp: Promise<Response>): Promise<T> {
  return resp
    .then(response => {
      if (!response.ok) {
        console.log('error', response.status, response.statusText)
        if (response.status === 403) {
          return Promise.reject(new Error("Unauthorized"))
        }

        return response
          .text()
          .then(text => {
            throw new Error(text)
          })
      }
      return response.json() as Promise<T>
    })
    .then(data => {
      console.log('response', data)
      return data
    })
}

export function apiGet<T>(url: string): Promise<T> {
  return onResponse(fetch(ApiRoot + url, {headers: {Authorization: `bearer ${getToken()}`}}))
}

export function apiDelete<T>(url: string, body?: object, token?: string): Promise<T> {
  if (!token) {
    token = getToken();
  }
  const requestInit = {
    method: "DELETE",
    headers: {Authorization: `bearer ${token}`}
  } as RequestInit
  if (body) {
    requestInit.body = JSON.stringify(body);
  }
  return onResponse(fetch(ApiRoot + url, requestInit))
}

export function apiPost<T>(url: string, body?: object, token?: string): Promise<T> {
  if (!token) {
    token = getToken();
  }
  const requestInit = {
    method: "POST",
    headers: {Authorization: `bearer ${token}`}
  } as RequestInit
  if (body) {
    requestInit.body = JSON.stringify(body);
  }
  return onResponse(fetch(ApiRoot + url, requestInit))
}

  export function apiPut<T>(url: string, body?: object, token?: string): Promise<T> {
    if (!token) {
      token = getToken();
    }
    const requestInit = {
      method: "PUT",
      headers: {Authorization: `bearer ${token}`}
    } as RequestInit
    if (body) {
      requestInit.body = JSON.stringify(body);
    }
    return onResponse(fetch(ApiRoot + url, requestInit))
}

