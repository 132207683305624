import {useState} from "react"
import MsisdnForm from "./MsisdnForm"
import CodeForm from "./CodeForm"
import {useAppContext} from "../AppContextProvider"
import {ActionKind} from "../../state/appReducer"
import {useLocation, useNavigate} from "react-router-dom"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import {Typography} from "@mui/material"
import logo from "../../assets/Slice.png"

enum LoginStep {
  INITIAL,
  MSISDN_SENT
}

interface LoginState {
  step: LoginStep,
  msisdn?: string
}

export default function Login() {

  const [loginState, setLoginState] = useState<LoginState>({step: LoginStep.INITIAL})

  const {dispatch} = useAppContext()

  const location = useLocation()
  const navigate = useNavigate()

  let stepComponent;

  switch (loginState.step) {
    case LoginStep.MSISDN_SENT:
      stepComponent =
        <CodeForm msisdn={loginState.msisdn || ''}
                  onSuccess={token => {
                    console.log('authenticated', token)
                    dispatch({type: ActionKind.SET_TOKEN, payload: {token: token}})
                    if (location.pathname === '/') {
                      navigate('/report')
                    }
                  }}
                  onReset={() => {
                    console.log('reset');
                    setLoginState({step: LoginStep.INITIAL, msisdn: undefined})
                  }}
        />
      break
    default:
      stepComponent =
        <MsisdnForm onSuccess={msisdn => setLoginState({step: LoginStep.MSISDN_SENT, msisdn: msisdn})}/>
  }
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <CssBaseline/>
      <Box sx={{mt: '35vh', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <img width='80' src={logo} alt=''/>
        <Typography variant='h6' align='center'>Консоль поддержки</Typography>
        {stepComponent}
      </Box>
    </Box>
  )
}
