import createTheme from "@mui/material/styles/createTheme";

export default function darkTheme() {
  return createTheme({
    palette: {
      mode: 'dark',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Some CSS
            borderRadius: '15px',
          },
        },
      },
    }
  })
}

