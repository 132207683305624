import {Banner, Campaign, Command, Filter, Region, rotatorApi, Service} from "../../api/rotator"
import * as React from "react";
import {useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {v4 as uuidv4} from "uuid";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useSnackbar} from "notistack";
import {ExpandMore} from "@mui/icons-material";
import logout from "../../handlers/auth/logout";
import {useAppContext} from "../AppContextProvider";
import {useNavigate} from "react-router-dom";
import {
    BarElement,
    ChartConfiguration,
    LineController,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import {Bar, Chart, Line} from 'react-chartjs-2';

ChartJS.register(
    LineController,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

function options(text: string) {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: text,
            },
        },
    }
}

export const regionOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

interface Column {
    id: string;
    label: string;
    align?: 'right' | 'center';
}

const filterTypes = [
    {
        value: 'region',
        label: 'Регион',
    },
    {
        value: 'balance',
        label: 'Баланс',
    }]

const smmpConnection = ['ext', 'a1', 'clouty', 'ussdbank'];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const columns: readonly Column[] = [
    {id: 'id', label: 'Id', align: 'center'},
    {id: 'name', label: 'Наименование Кампании', align: 'center'},
    {id: 'start', label: 'Дата начала', align: 'center'},
    {id: 'status', label: 'Статус', align: 'center'},
    {id: 'action', label: 'Действия', align: 'center'}
]

let editCampaign: Campaign

let invalidBanners: Banner[];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

let isNeedUpdateCharts = true;


export default function CampaignsList() {
    const {dispatch} = useAppContext()
    const navigate = useNavigate()
    const [campaigns, setCampaignsData] = useState<Campaign[]>([])
    const [loading, setLoading] = useState(false)
    const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false)
    const [activeModalIsOpen, setActiveModalIsOpen] = useState(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [bannerModalIsOpen, setBannerEditModalIsOpen] = useState(false)
    const [editCommands, setEditCommands] = useState<Command[]>([])
    const [editBanners, setEditBanners] = useState<Banner[]>([])
    const {enqueueSnackbar} = useSnackbar();
    const [regions, setRegions] = useState<Map<any, Region>>(new Map())
    const theme = useTheme();
    const [tempRegions, setTempRegions] = React.useState<string[]>([]);
    const handleChange = (event: SelectChangeEvent<string[]>, filter: Filter) => {
        const {
            target: {value},
        } = event;
        setTempRegions(typeof value === 'string' ? value.split(',') : value,)
        filter["@_value"] = Array.isArray(value) ? value.join() : value;
    };

    function logoutIfUnauthorized(errorMsg: string | undefined) {
        if (errorMsg && errorMsg === 'Unauthorized') {
            logout(dispatch, navigate);
        }
    }

    const closeRemoveModal = () => {
        setRemoveModalIsOpen(false)
    };

    const closeActiveModal = () => {
        setActiveModalIsOpen(false)
    }

    function refreshBanner(banner: Banner, index: number, isNeedUpdateChart: boolean) {
        isNeedUpdateCharts = isNeedUpdateChart;
        setEditBanners(existingItems => {
            return [
                ...existingItems.slice(0, index),
                banner,
                ...existingItems.slice(index + 1),
            ]
        })
    }

    const closeEditModal = () => {
        setEditModalIsOpen(false)
        loadData()
    };

    const closeBannerModal = () => {
        setBannerEditModalIsOpen(false)
    };

    const openBannerModal = () => {
        setBannerEditModalIsOpen(true);
    }

    const deleteClick = (campaign: Campaign) => {
        setRemoveModalIsOpen(true);
        rotatorApi.deleteCampaign(campaign).then(() => loadData())
    };

    const editClick = (campaign: Campaign) => {
        setEditModalIsOpen(true)
        editCampaign = campaign
        setEditCommands(editCampaign?.command)
        for (let i = 0; i < editCampaign.banner.length; i++) {
            if (editCampaign.banner[i]["@_displayOrder"] == null || editCampaign.banner[i]["@_displayOrder"] == 0) {
                editCampaign.banner[i]["@_displayOrder"] = 1
            }
        }
        setEditBanners(editCampaign?.banner)
    };

    const activeClick = (campaign: Campaign) => {
        rotatorApi.activateCampaign(campaign).then(() => loadData())
        setActiveModalIsOpen(true);
    };

    const copyClick = (campaign: Campaign) => {
        rotatorApi.copyCampaign(campaign).then(() => loadData())
    };

    function loadData() {
        setLoading(true);
        rotatorApi.getRegions().then(response => {
            setRegions(response)
            setRegions(response => new Map([...Object.entries(response), ['*', {
                Name: "Все регионы",
                Org: "asd"
            } as Region]]))
        })
        rotatorApi.getAllCampaigns().then(response => {
            Array.isArray(response)
                ? setCampaignsData(response)
                : setCampaignsData(Array(response))
        }, error => {
            logoutIfUnauthorized(error.message)
            enqueueSnackbar('Ошибка загрузки списка Кампаний: ' + error.message, {variant: 'error'});
        })
            .then(() => {
                setLoading(false)
                tableBody()
            })
    }


    const handleLoadButtonClick = () => {
        loadData()
    }

    function checkBannerByBalance() {
        invalidBanners = editCampaign.banner
            .filter(x => !checkBalanceFilter(Array.isArray(x.filter)
                ? x.filter.find(x => x["@_type"] === 'balance')
                : x.filter));
        if (invalidBanners != null && invalidBanners.length !== 0) {
            setBannerEditModalIsOpen(true)
        } else {
            confirmEdit()
        }
    }

    function confirmEdit() {
        if (editCampaign != null) {
            editCommands ? editCampaign.command = editCommands : editCampaign.command = []
            editBanners ? editCampaign.banner = editBanners : editCampaign.banner = []
            rotatorApi.updateCampaign(editCampaign).then(() => loadData())
            invalidBanners = []
            setEditModalIsOpen(false)
        }
    }

    function setBannerServiceValue(value: string, service: Service | Service[] | undefined) {
        (service as Service)["@_value"] = value
    }

    function setBannerFilterType(type: string, filter: any) {
        (filter as Filter)["@_type"] = type
    }

    function setBannerFilterValue(value: string, filter: any) {
        (filter as Filter)["@_value"] = value
    }


    const deleteCommandClick = (command: Command) => {
        if (editCampaign != null) {
            const listCommand = editCampaign.command.filter(com => com["@_id"] !== command["@_id"]);
            setEditCommands(listCommand)
            editCampaign.command = listCommand
        }
    }

    const copyCommandClick = (command: Command) => {
        if (editCampaign != null) {
            const newCommand: Command = structuredClone(command);
            newCommand["@_id"] = command["@_id"] + " copy"
            setEditCommands(oldArr => [...oldArr, newCommand])
        }
    }

    function copyBannerClick(banner: Banner) {
        if (editCampaign != null) {
            const newBanner: Banner = structuredClone(banner);
            newBanner["@_displayOrder"] = parseInt(newBanner["@_displayOrder"].toString()) + 1
            newBanner["@_id"] = newBanner["@_id"] + " copy"
            setEditBanners(oldArray => [...oldArray, newBanner])
        }
    }

    function getCommands() {
        return <Box component="div" sx={{
            '& > :not(style)': {m: 1},
        }}
                    key={uuidv4()}
        >
            {editCommands?.map((command) => (
                <Box component="div" sx={{
                    '& > :not(style)': {m: 1},
                    padding: 1.5,
                    border: 1,
                    borderRadius: '16px'
                }}
                     key={uuidv4()}
                >
                    <h4>{command["@_name"]}</h4>
                    <h4>{command["@_id"]}</h4>
                    <TextField required
                               margin="normal"
                               label="Идентификатор команды"
                               defaultValue={command["@_id"]}
                               onChange={(event) => {
                                   command["@_id"] = event.target.value
                               }}/>
                    <TextField margin="normal"
                               label="Наименование команды"
                               defaultValue={command["@_name"]}
                               onChange={(event) => {
                                   command["@_name"] = event.target.value
                               }}/>
                    <TextField select
                               margin="normal"
                               label="Название SMPP соединения"
                               SelectProps={{
                                   native: true,
                               }}
                               defaultValue={command.connection}
                               onChange={(event) => {
                                   command.connection = event.target.value
                               }}>
                        {smmpConnection.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField required
                               label="Адрес получателя команды"
                               margin="normal"
                               defaultValue={command.da}
                               inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                               onChange={(event) => {
                                   command.da = Number(event.target.value)
                               }}/>
                    <TextField required
                               label="Текст, передаваемый в сообщении"
                               fullWidth
                               margin="normal"
                               defaultValue={command.message}
                               onChange={(event) => {
                                   command.message = event.target.value
                               }}/>
                    <IconButton
                        id="delete-command-button"
                        onClick={() => deleteCommandClick(command)}
                        sx={{fontSize: 12}}>
                        Удалить
                        <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton
                        id="copy-command-button"
                        onClick={() => copyCommandClick(command)}
                        sx={{fontSize: 12}}>
                        Дублировать
                        <ContentCopyIcon/>
                    </IconButton>
                </Box>
            ))}
        </Box>;
    }

    function deleteBannerClick(banner: Banner) {
        if (editCampaign != null) {
            const listBanner = editCampaign.banner.filter(com => com["@_id"] !== banner["@_id"]);
            setEditBanners(listBanner)
            editCampaign.banner = listBanner
        }
    }

    function upBannerClick(banner: Banner) {
        if (editCampaign != null) {
            if (banner["@_displayOrder"] > 1) {
                banner["@_displayOrder"] = parseInt(banner["@_displayOrder"].toString()) - 1;
            }
        }
    }

    function downBannerClick(banner: Banner) {
        banner["@_displayOrder"] = parseInt(banner["@_displayOrder"].toString()) + 1;
    }

    function deleteServiceClick(banner: Banner, service: any, index: number) {
        if (editCampaign != null) {
            if (Array.isArray(banner.service)) {
                banner.service = banner.service.filter(serv => serv !== service);
            } else {
                banner.service = []
            }
            refreshBanner(banner, index, true)
        }
    }

    function addServiceClick(banner: Banner, index: number) {
        if (editCampaign != null) {
            if (Array.isArray(banner.service)) {
                banner.service.push({"@_value": ""});
            } else {
                if (banner.service == null) {
                    banner.service = [{"@_value": ""}]
                } else {
                    banner.service = [banner.service]
                    banner.service.push({"@_value": ""})
                }
            }
            refreshBanner(banner, index, true)
        }
    }

    function addFilterClick(banner: Banner, index: number) {
        if (editCampaign != null) {
            if (Array.isArray(banner.filter)) {
                let filter = banner.filter.find(x => x["@_type"] === 'region');
                let isRegionType: boolean = filter !== undefined
                banner.filter.push({
                    "@_type": isRegionType ? "balance" : "region",
                    "@_value": isRegionType ? "" : ""
                });
            } else {
                if (banner.filter == null) {
                    banner.filter = [{
                        "@_type": "region",
                        "@_value": ""
                    }]
                } else {
                    let isRegionType: boolean = banner.filter["@_type"] === "region"
                    banner.filter = [banner.filter]
                    banner.filter.push({
                        "@_type": isRegionType ? "balance" : "region",
                        "@_value": isRegionType ? "" : "*"
                    })
                }
            }
            refreshBanner(banner, index, false)
        }
    }

    function deleteFilterClick(banner: Banner, filter: any, index: number) {
        if (editCampaign !== null) {
            if (Array.isArray(banner.filter)) {
                banner.filter = banner.filter.filter(fil => fil !== filter);
            } else {
                banner.filter = []
            }
            refreshBanner(banner, index, true)
        }
    }

    function checkBalanceFilter(filter: Filter | undefined) {
        if (editCampaign["@_mandatoryBalanceInterval"] != null && filter != null && filter["@_type"] === 'balance') {
            let expression: string = editCampaign["@_mandatoryBalanceInterval"].replace(/[-\d]+/g, "").trim();
            let number: number = parseInt(editCampaign["@_mandatoryBalanceInterval"].replace(/[^-\d]/g, ""));
            let start = null;
            let finish = null;
            let filterExpression = null;
            let filterNumber = null;
            if (filter["@_value"].includes("до")) {
                let split = filter["@_value"].split("до");
                start = parseInt(split[0].replace(/[^-\d]/g, ""));
                finish = parseInt(split[1].replace(/[^-\d]/g, ""));
            } else {
                filterExpression = filter["@_value"].replace(/[-\d]+/g, "").trim();
                filterNumber = parseInt(filter["@_value"].replace(/[^-\d]/g, ""));
            }

            switch (expression) {
                case ">":
                    if (start != null && finish != null) {
                        return number > start
                    } else {
                        switch (filterExpression) {
                            case ">":
                                return true;
                            case ">=":
                                return true;
                            case "<":
                                if (filterNumber != null) {
                                    return number < filterNumber - 1;
                                } else {
                                    return false;
                                }
                            case "<=":
                                if (filterNumber != null) {
                                    return number < filterNumber;
                                } else {
                                    return false;
                                }
                        }
                    }
                    break;
                case ">=":
                    if (start != null && finish != null) {
                        return number >= start
                    } else {
                        switch (filterExpression) {
                            case ">":
                                return true;
                            case ">=":
                                return true;
                            case "<":
                                if (filterNumber != null) {
                                    return number < filterNumber;
                                } else {
                                    return false;
                                }
                            case "<=":
                                if (filterNumber != null) {
                                    return number <= filterNumber;
                                } else {
                                    return false;
                                }
                        }
                    }
                    break;
                case "<":
                    if (start != null && finish != null) {
                        return start < number
                    } else {
                        switch (filterExpression) {
                            case ">":
                                if (filterNumber != null) {
                                    return number > filterNumber + 1
                                } else {
                                    return false
                                }
                            case ">=":
                                if (filterNumber != null) {
                                    return number > filterNumber
                                } else {
                                    return false
                                }
                            case "<":
                                return true;
                            case "<=":
                                return true;
                        }
                    }
                    break;
                case "<=":
                    if (start != null && finish != null) {
                        return number <= finish
                    } else {
                        switch (filterExpression) {
                            case ">":
                                if (filterNumber != null) {
                                    return number > filterNumber
                                } else {
                                    return false
                                }
                            case ">=":
                                if (filterNumber != null) {
                                    return number >= filterNumber
                                } else {
                                    return false
                                }
                            case "<":
                                return true;
                            case "<=":
                                return true;
                        }
                    }
                    break;
            }
        } else {
            return true;
        }
    }

    function getBanners() {
        return <Box component="div" sx={{
            '& > :not(style)': {m: 1},
        }}
        >

            {editBanners?.sort((x, y) => x["@_displayOrder"] - y["@_displayOrder"]).map((banner, index) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box component="div" sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: 1
                        }}
                             key={banner["@_id"]}
                        >
                            <p>Идентификатор: {banner["@_id"]}</p>
                            <p>Порядок показа: {banner["@_displayOrder"]}</p>
                            <Box component="div"
                            >
                                <IconButton
                                    id="up-banner-button"
                                    sx={{
                                        fontSize: 12
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        upBannerClick(banner)
                                        refreshBanner(banner, index, false)
                                    }}>
                                    <p>Верх</p>
                                    <ArrowUpwardIcon/>
                                </IconButton>
                                <IconButton
                                    id="down-banner-button"
                                    sx={{fontSize: 12}}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        downBannerClick(banner)
                                        refreshBanner(banner, index, false)
                                    }}>
                                    <p>Вниз</p>
                                    <ArrowDownwardIcon/>
                                </IconButton>
                                <IconButton
                                    id="delete-banner-button"
                                    sx={{fontSize: 12}}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        deleteBannerClick(banner)
                                    }}>
                                    <p>Удалить</p>
                                    <DeleteForeverIcon/>
                                </IconButton>
                                <IconButton
                                    id="copy-banner-button"
                                    sx={{fontSize: 12}}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        copyBannerClick(banner)
                                    }}>
                                    <p>Дублировать</p>
                                    <ContentCopyIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="div" sx={{
                            '& > :not(style)': {m: 1},
                            border: 1,
                            padding: 1.5,
                            borderRadius: '16px'
                        }}
                             key={banner["@_id"]}
                        >
                            <TextField required
                                       margin="normal"
                                       label="Идентификатор баннера"
                                       fullWidth
                                       defaultValue={banner["@_id"]}
                                       onChange={(event) => {
                                           banner["@_id"] = event.target.value
                                       }}/>
                            <TextField margin="normal"
                                       label="Текстовое описание"
                                       fullWidth
                                       defaultValue={banner.description}
                                       onChange={(event) => {
                                           banner.description = event.target.value
                                       }}/>
                            <Box component="div" sx={{
                                '& > :not(style)': {m: 1},
                            }}
                            >
                                <h5>Сервисы:</h5>
                                <IconButton
                                    id="add-service-button"
                                    sx={{fontSize: 12}}
                                    onClick={() => addServiceClick(banner, index)}>
                                    <p>Добавить сервис</p>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                                {Array.isArray(banner.service) ? banner.service?.map((service) => (
                                        <Box component="div" sx={{
                                            '& > :not(style)': {m: 1},
                                            border: 1,
                                            padding: 1.5,
                                            borderRadius: '16px',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                             key={uuidv4()}
                                        >
                                            <TextField required
                                                       label="Сервисный номер"
                                                       margin="normal"
                                                       defaultValue={service["@_value"]}
                                                       onChange={(event) => {
                                                           service["@_value"] = event.target.value
                                                       }}/>
                                            <IconButton
                                                id="delete-service-button"
                                                sx={{fontSize: 12}}
                                                onClick={() => deleteServiceClick(banner, service, index)}>
                                                <p>Удалить</p>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Box>)) :
                                    banner.service ?
                                        <Box component="div" sx={{
                                            '& > :not(style)': {m: 1},
                                        }}
                                        >
                                            <TextField required
                                                       label="Сервис для которого актуален баннер"
                                                       margin="normal"
                                                       defaultValue={banner.service?.["@_value"]}
                                                       onChange={(event) => {
                                                           setBannerServiceValue(event.target.value, banner.service)
                                                       }}/>
                                            <IconButton
                                                id="delete-service-button"
                                                sx={{fontSize: 12}}
                                                onClick={() => deleteServiceClick(banner, banner.service, index)}>
                                                <p>Удалить</p>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Box> : <></>}
                            </Box>
                            <TextField required
                                       label="Текст баннера"
                                       fullWidth
                                       margin="normal"
                                       defaultValue={banner.text}
                                       onChange={(event) => {
                                           banner.text = event.target.value
                                       }}/>
                            <TextField select
                                       label="Команда"
                                       margin="normal"
                                       defaultValue={banner.command["@_idref"]}
                                       SelectProps={{
                                           native: true,
                                       }}
                                       onChange={(event) => {
                                           banner.command["@_idref"] = event.target.value
                                       }}>
                                {editCommands.map((option) => (
                                    <option key={option["@_id"]} value={option["@_id"]}>
                                        {option["@_id"]}
                                    </option>
                                ))}
                            </TextField>
                            <Box component="div" sx={{
                                '& > :not(style)': {m: 1},
                            }}
                            >
                                <h5>Фильтры</h5>
                                <IconButton
                                    disabled={banner.filter !== undefined && Array.isArray(banner.filter) ? banner.filter.length >= 2 : false}
                                    id="add-filter-button"
                                    sx={{fontSize: 12}}
                                    onClick={() => addFilterClick(banner, index)}>
                                    <p>Добавить фильтр</p>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                                {Array.isArray(banner.filter) ? banner.filter?.map((filter) => (
                                        <Box component="div" sx={{
                                            '& > :not(style)': {m: 1},
                                            border: 1,
                                            borderRadius: '16px',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                             key={uuidv4()}
                                        >
                                            <Box component="div" sx={{
                                                '& > :not(style)': {m: 1},
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                                <TextField select
                                                           label="Тип фильтра"
                                                           margin="normal"
                                                           defaultValue={filter["@_type"]}
                                                           SelectProps={{
                                                               native: true,
                                                           }}
                                                           onChange={(event) => {
                                                               filter["@_type"] = event.target.value
                                                               refreshBanner(banner, index, true)
                                                           }}>
                                                    {filterTypes.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                {filter["@_type"] === 'region'
                                                    ? <FormControl sx={{m: 1, width: 300}} key={uuidv4()}>
                                                        <InputLabel id="demo-multiple-chip-label">Номера
                                                            регионов</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={filter["@_value"] === "" ? [] : filter["@_value"].split(",")}
                                                            onChange={(event) => handleChange(event, filter)}
                                                            input={<OutlinedInput id="select-multiple-chip"
                                                                                  label="Номера регионов"/>}
                                                            renderValue={(selected) => (
                                                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value}/>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {Array.from(regions).map((entry) => (
                                                                <MenuItem
                                                                    key={entry[0]} value={entry[0]}
                                                                    style={getStyles(entry[1].Name, filter["@_value"].split(","), theme)}
                                                                >
                                                                    {entry[1].Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> :
                                                    <Box component="div" sx={{
                                                            display: 'flex',
                                                        justifyContent: 'space-between'}}>
                                                        <TextField required
                                                                   label="Значение фильтра"
                                                                   error={!checkBalanceFilter(filter)}
                                                                   helperText={checkBalanceFilter(filter) ? "" : "Баннер не будет покрывать обязательный интервал баланса"}
                                                                   defaultValue={filter["@_value"]}
                                                                   onBlur={() => {
                                                                       refreshBanner(banner, index, true)
                                                                   }}
                                                                   onChange={(event) => {
                                                                       filter["@_value"] = event.target.value;
                                                                   }}/>
                                                        <Box component="div"sx={{
                                                            marginLeft: 10,
                                                            fontSize: 10}}>
                                                            <p style={{margin: 0}}>Значение фильтра может иметь вид:</p>
                                                            <p style={{margin: 0}}>(&gt; | &gt;= | &lt; | &lt;=) пример: &gt;= 10</p>
                                                            <p style={{margin: 0}}>или (от и до) пример: от -5 до 10 </p>
                                                        </Box>
                                                    </Box>}
                                            </Box>
                                            <IconButton
                                                id="delete-filter-button"
                                                sx={{fontSize: 12}}
                                                onClick={() => deleteFilterClick(banner, filter, index)}>
                                                <p>Удалить</p>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Box>))
                                    : banner.filter ?
                                        <Box component="div" sx={{
                                            '& > :not(style)': {m: 1},
                                        }}
                                             key={uuidv4()}
                                        >

                                            <TextField select
                                                       label="Тип фильтра"
                                                       margin="normal"
                                                       defaultValue={banner.filter?.["@_type"]}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       onChange={(event) => {
                                                           setBannerFilterType(event.target.value, banner.filter)
                                                           refreshBanner(banner, index, true)
                                                       }}>
                                                {filterTypes.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <TextField select
                                                       label="Значение фильтра"
                                                       margin="normal"
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       defaultValue={banner.filter?.["@_value"]}
                                                       onChange={(event) => {
                                                           setBannerFilterValue(event.target.value, banner.filter)
                                                       }}>
                                                {Array.from(regions).map((entry) => (
                                                    <option key={entry[0]} value={entry[0]}>
                                                        {entry[1].Name}
                                                    </option>
                                                ))}
                                            </TextField>
                                            <IconButton
                                                id="delete-filter-button"
                                                sx={{fontSize: 12}}
                                                onClick={() => deleteFilterClick(banner, banner.filter, index)}>
                                                <p>Удалить</p>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Box> : <></>}
                                <FormControlLabel
                                    control={<Checkbox required
                                                       checked={banner.needPromo}
                                                       onChange={(event) => {
                                                           banner.needPromo = event.target.checked
                                                           refreshBanner(banner, index, false)
                                                       }}/>}
                                    label="Показывать доп. промо"/>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    }

    function getInformation() {
        return <Box component="div" sx={{
            '& > :not(style)': {m: 1},
        }}
        >
            <TextField
                label="Идентификатор"
                fullWidth
                margin="normal"
                disabled={true}
                defaultValue={editCampaign["@_id"]}
                InputProps={{
                    readOnly: true,
                }}/>
            <TextField required
                       label="Наименование"
                       fullWidth
                       margin="normal"
                       defaultValue={editCampaign["@_name"]}
                       onChange={(event) => {
                           editCampaign["@_name"] = event.target.value
                       }}
            />
            <TextField label="Статус"
                       fullWidth
                       margin="normal"
                       disabled={true}
                       defaultValue={editCampaign["@_status"]}
                       InputProps={{
                           readOnly: true,
                       }}/>
            <TextField label="Дата начала кампании"
                       fullWidth
                       margin="normal"
                       disabled={true}
                       defaultValue={editCampaign["@_start"]}
                       InputProps={{
                           readOnly: true,
                       }}/>
            <TextField label="Обязательный интервал баланса, который должен покрываться баннерами кампании"
                       fullWidth
                       margin="normal"
                       defaultValue={editCampaign["@_mandatoryBalanceInterval"]}
                       onChange={(event) => {
                           editCampaign["@_mandatoryBalanceInterval"] = event.target.value
                       }}/>
        </Box>
    }

    function getStratagy() {
        return <Box component="div" sx={{
            '& > :not(style)': {m: 1},
        }}>
            {editCampaign.strategy.param.map((param) => (
                <Box>
                    {param["@_name"] === "X" && <p>
                        параметр X: Максимальное колличество запросов в Y суток для отсечения m2m
                    </p>}
                    {param["@_name"] === "Y" && <p>
                        параметр Y: Счётчик дней в м2м сервисе
                    </p>}
                    {param["@_name"] === "N" && <p>
                        параметр N: Настройка очередности показа - N (в штуках) для подключния доп.
                        промо,
                        каждый N-ый уникальный msisdn получает дом. промо
                    </p>}
                    {param["@_name"] === "H" && <p>
                        параметр H: Настройка времени хранения кэша уникальных показов в календарных
                        сутках
                    </p>}
                    <TextField required
                               margin="normal"
                               key={uuidv4()}
                               label={param["@_name"]}
                               defaultValue={param["@_value"]}
                               onChange={(event) => {
                                   param["@_value"] = event.target.value
                               }}
                    />
                </Box>
            ))}
        </Box>
    }

    function setCountByBalance(filter: Filter, mapa: Map<string, number>) {
        if (filter["@_type"] === 'balance') {
            if (filter["@_value"].includes("до")) {
                var split = filter["@_value"].split("до");
                var start: number = parseInt(split[0].replace(/[^-\d]/g, ""));
                var finish: number = parseInt(split[1].replace(/[^-\d]/g, ""));
                for (let i = start; i <= finish; i++) {
                    let value = mapa.get(i.toString());
                    if (value == null) {
                        value = 1
                    } else {
                        value = value + 1
                    }
                    mapa.set(i.toString(), value);
                }
            } else {
                var expression: string = filter["@_value"].replace(/[-\d]+/g, "").trim();
                var number: number = parseInt(filter["@_value"].replace(/[^-\d]/g, ""));
                switch (expression) {
                    case ">":
                        for (let i = number + 1; i < 100; i++) {
                            let value = mapa.get(i.toString());
                            if (value == null) {
                                value = 1
                            } else {
                                value = value + 1
                            }
                            mapa.set(i.toString(), value);
                        }
                        break
                    case ">=" :
                        for (let i = number; i < 100; i++) {
                            let value = mapa.get(i.toString());
                            if (value == null) {
                                value = 1
                            } else {
                                value = value + 1
                            }
                            mapa.set(i.toString(), value);
                        }
                        break;
                    case "<" :
                        for (let i = -100; i < number; i++) {
                            let value = mapa.get(i.toString());
                            if (value == null) {
                                value = 1
                            } else {
                                value = value + 1
                            }
                            mapa.set(i.toString(), value);
                        }
                        break;
                    case "<=" :
                        for (let i = -100; i <= number; i++) {
                            let value = mapa.get(i.toString());
                            if (value == null) {
                                value = 1
                            } else {
                                value = value + 1
                            }
                            mapa.set(i.toString(), value);
                        }
                        break
                }
            }
        }
    }


    function setBalanceMapCountByBanner(banner: Banner, mapa: Map<string, number>) {
        if (banner.filter == null) {
            mapa.forEach((value, key, map) => {
                mapa.set(key, value + 1);
            })
        } else if (Array.isArray(banner.filter)) {
            if (banner.filter.length === 0) {
                mapa.forEach((value, key, map) => {
                    mapa.set(key, value + 1);
                })
            } else {
                banner.filter.forEach((filter) => {
                    setCountByBalance(filter, mapa);
                })
            }
        } else {
            setCountByBalance(banner.filter, mapa)
        }

    }

    function setBalanceInterval() {
        if (editCampaign["@_mandatoryBalanceInterval"] != null) {
            let expression: string = editCampaign["@_mandatoryBalanceInterval"].replace(/[-\d]+/g, "").trim();
            let number: number = parseInt(editCampaign["@_mandatoryBalanceInterval"].replace(/[^-\d]/g, ""));
            let map = new Map<string, number>;
            switch (expression) {
                case "<":
                    for (let i = -100; i < number; i++) {
                        map.set(i.toString(), 0.7)
                    }
                    break;
                case "<=":
                    for (let i = -100; i <= number; i++) {
                        map.set(i.toString(), 0.7)
                    }
                    return map
                case ">":
                    for (let i = number + 1; i < 100; i++) {
                        map.set(i.toString(), 0.7)
                    }
                    break;
                case ">=":
                    for (let i = number; i < 100; i++) {
                        map.set(i.toString(), 0.7)
                    }
                    break;
            }
            return map
        }
    }

    function getBalanceDataByService(service: string) {
        let mapa = new Map<string, number>();
        for (let i = -100; i < 100; i++) {
            mapa.set(i.toString(), 0);
        }
        let balanceIntervalMap = setBalanceInterval();
        editBanners.forEach((banner) => {
            if (banner.service == null) {
                setBalanceMapCountByBanner(banner, mapa);
            } else if (Array.isArray(banner.service) && banner.service.find(x => x["@_value"] === service) != null) {
                setBalanceMapCountByBanner(banner, mapa)
            } else if (!Array.isArray(banner.service) && banner.service["@_value"] === service) {
                setBalanceMapCountByBanner(banner, mapa)
            }
        })
        let strings = Array.from(mapa.keys());

        return {
            labels: strings,
            datasets: [
                {
                    type: 'bar' as const,
                    label: 'Колличество баннеров охватывающих данный баланс',
                    data: strings.map((s) => mapa.get(s)),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    type: 'line' as const,
                    label: 'Обязательный интервал баланса, который должен покрываться баннерами',
                    pointRadius: 1,
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 1,
                    fill: false,
                    data: strings.map((s) => balanceIntervalMap?.get(s)),
                }
            ],
        }
    }

    function setCountByRegion(filter: Filter, mapa: Map<string, number>) {
        if (filter["@_type"] === 'region') {
            var split = filter["@_value"].split(",");
            for (let i = 0; i < split.length; i++) {
                if (split[i] === "*" && i !== 0) {
                    split[i] = split[0];
                    split[0] = "*";
                }
            }
            for (let i = 0; i < split.length; i++) {
                if (split[i] === "*") {
                    mapa.forEach((value, key, map) => {
                        mapa.set(key, value + 1);
                    })
                    break;
                }
                if (split[i] !== '') {
                    let value = mapa.get(split[i]);
                    if (value == null) {
                        value = 1
                    } else {
                        value = value + 1
                    }
                    mapa.set(split[i], value);
                }
            }
        }
    }

    function setRegionMapCountByBanner(banner: Banner, mapa: Map<string, number>) {
        if (banner.filter == null) {
            mapa.forEach((value, key, map) => {
                mapa.set(key, value + 1);
            })
        } else if (Array.isArray(banner.filter)) {
            if (banner.filter.length === 0) {
                mapa.forEach((value, key, map) => {
                    mapa.set(key, value + 1);
                })
            } else {
                banner.filter.forEach((filter) => {
                    setCountByRegion(filter, mapa);
                })
            }
        } else {
            setCountByRegion(banner.filter, mapa)
        }
    }

    function getRegionDataByService(service: string) {
        let mapa = new Map<string, number>();
        regions.forEach((value, key, map) => {
            mapa.set(key as string, 0);
        });
        mapa.delete("*")
        editBanners.forEach((banner) => {
            if (banner.service == null) {
                setRegionMapCountByBanner(banner, mapa);
            } else if (Array.isArray(banner.service) && banner.service.find(x => x["@_value"] === service) != null) {
                setRegionMapCountByBanner(banner, mapa)
            } else if (!Array.isArray(banner.service) && banner.service["@_value"] === service) {
                setRegionMapCountByBanner(banner, mapa)
            }
        })
        let strings = Array.from(mapa.keys());
        return {
            labels: strings,
            datasets: [
                {
                    label: 'Колличество баннеров охватывающих данный регион',
                    data: strings.map((s) => mapa.get(s)),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        }
    }

    function getCharts() {
        if (isNeedUpdateCharts) {
            isNeedUpdateCharts = false;
            let services = new Set<string>();
            editBanners.forEach(x => {
                if (Array.isArray(x.service)) {
                    x.service.forEach(y => services.add(y["@_value"]))
                } else {
                    if (x.service != null) {
                        services.add(x.service["@_value"])
                    }
                }
            })
            return <Box component="div">
                {Array.from(services).map((service: string) => (
                    <Grid container
                          direction="row"
                          justifyContent="space-between"
                          sx={{
                              maxWidth: '100%',
                          }}>
                        <h3>{"Сервис: " + service}</h3>
                        <Grid item xs={5}><Chart type='bar' options={options("График отображения баннеров по балансу")}
                                                 data={getBalanceDataByService(service)}/></Grid>
                        <Grid item xs={5}><Bar options={options("График отображения баннеров по номеру регионов")}
                                               data={getRegionDataByService(service)}/></Grid>
                    </Grid>
                ))}
            </Box>
        }
    }

    function infoModal(invalidBanners: Banner[]) {
        if (invalidBanners != null && invalidBanners.length != 0) {
            return <Modal open={bannerModalIsOpen}>
                <Box sx={{...style, width: 600}}>
                    <h3>Внимание! {invalidBanners.length} невалидных баннеров!</h3>
                    <div>
                        {invalidBanners.map((banner) => (
                            <p>Баннер с идентификатором: {banner["@_id"]}. Не входит в обязательный интервал баланса</p>
                        ))}
                    </div>
                    <p style={{textAlign: "center"}}>Сохранить кампанию?</p>
                    <Grid container direction="row" justifyContent="space-around">
                        <Button onClick={() => setBannerEditModalIsOpen(false)}>Отмена</Button>
                        <Button onClick={() => {
                            setBannerEditModalIsOpen(false);
                            confirmEdit()
                        }}>Ок</Button>
                    </Grid>
                </Box>
            </Modal>
        } else {
            return <></>
        }
    }

    function redactor() {
        if (editCampaign != null) {
            return <Container maxWidth="lg">
                <Grid container sx={{
                    maxWidth: '100%',
                }}>
                    <h2 id="child-modal-title">Редактировать кампанию</h2>
                    <Box component="div" sx={{
                        '& > :not(style)': {m: 1},
                    }}
                    >
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Общая информация:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getInformation()}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Стратегия:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getStratagy()}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Команды:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getCommands()}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Баннеры:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {getBanners()}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
                {getCharts()}
                <Button onClick={closeEditModal}>Отмена</Button>
                <Button onClick={() => checkBannerByBalance()}>Подтвердить изменения</Button>
                {infoModal(invalidBanners)}
            </Container>
        } else {
            return <div/>
        }
    }

    const modal = () => {
        if (editCampaign != null && editModalIsOpen) {
            isNeedUpdateCharts = true;
            return <Modal hideBackdrop={true} open={editModalIsOpen}
                          style={{overflowY: "scroll", backgroundColor: "background"}}>
                {redactor()}
            </Modal>
        } else {
            return <></>
        }
    }

    function tableBody() {
        return <TableBody>
            {campaigns?.map((camp) => (
                <TableRow
                    key={camp["@_id"]}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                    <TableCell align="center">{camp["@_id"]}</TableCell>
                    <TableCell align="center">{camp["@_name"]}</TableCell>
                    <TableCell align="center">{camp["@_start"]}</TableCell>
                    <TableCell align="center">{camp["@_status"]}</TableCell>
                    <TableCell align="center">
                        <IconButton
                            id="edit-button"
                            disabled={camp["@_status"] === 'active'}
                            title="Редактировать"
                            onClick={() => editClick(camp)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton
                            id="remove-button"
                            disabled={camp["@_status"] === 'active'}
                            title="Удалить"
                            onClick={() => deleteClick(camp)}>
                            <DeleteForeverIcon/>
                        </IconButton>
                        <Modal open={removeModalIsOpen} onClose={closeRemoveModal}>
                            <Box sx={{...style, width: 400}}>
                                <h2 id="child-modal-title">Удаление кампании</h2>
                                <p id="child-modal-description">
                                    Выбраная вами кампания удалена
                                </p>
                                <Button onClick={closeRemoveModal}>Ок</Button>
                            </Box>
                        </Modal>
                        <IconButton
                            id="copy-button"
                            title="Копировать"
                            onClick={() => copyClick(camp)}>
                            <ContentCopyIcon/>
                        </IconButton>
                        <IconButton
                            id="active-button"
                            disabled={camp["@_status"] === 'active'}
                            title="Активировать"
                            onClick={() => activeClick(camp)}>
                            <DownloadDoneIcon/>
                        </IconButton>
                        <Modal open={activeModalIsOpen} onClose={closeActiveModal}>
                            <Box sx={{...style, width: 400}}>
                                <h2 id="child-modal-title">Aктивация кампании</h2>
                                <p id="child-modal-description">
                                    Выбраная вами кампания активирована
                                </p>
                                <Button onClick={closeActiveModal}>Ок</Button>
                            </Box>
                        </Modal>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    }

    return <>
        <h2>Список кампаний:</h2>
        <Grid container
              justifyContent="space-around"
              direction="row">
            <Button
                color='primary'
                disabled={loading}
                onClick={handleLoadButtonClick}>
                Загрузить\Обновить список кампаний
            </Button>
        </Grid>
        <Grid item xs={12}>
            {modal()}
            <Paper sx={{overflow: 'hidden'}}>
                <TableContainer sx={{maxHeight: '60vh'}}>
                    <Table stickyHeader aria-label="campaign list">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={uuidv4()}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {tableBody()}
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    </>
}