import {apiDelete, apiGet, apiPost, apiPut} from "./common";
const { v4: uuidv4 } = require('uuid');
const {XMLParser, XMLBuilder} = require('fast-xml-parser');

export interface Campaign {
    strategy: Strategy
    command: Command[]
    banner: Banner[]
    "@_id": string
    "@_status": string
    "@_start": string
    "@_name": string
    "@_mandatoryBalanceInterval": string
}
export interface xml {
    campaign: Campaign
}

export interface Strategy {
    param: Param[]
    "@_class": string
}

export interface Param {
    "@_name": string
    "@_value": string
}

export interface Command {
    connection: string
    da: number
    message: string
    "@_id": string
    "@_name": string
    "@_type": string
}

export interface Banner {
    description: string
    service?: Service[] | Service
    filter?: Filter[] | Filter
    text: string
    needPromo: boolean
    command: Command2
    "@_id": string
    "@_name": string
    "@_group": string
    "@_weight": string
    "@_displayOrder": number
}

export interface Service {
    "@_value": string
}

export interface Filter {
    "@_type": string
    "@_value": string
}

export interface Command2 {
    "@_idref": string
}

export interface Region {
    Name: string,
    Org: string
}


class RotatorApi {
     async getAllCampaigns(): Promise<Campaign[]> {
        const options = {
            ignorePiTags: true,
            ignoreAttributes: false,
            alwaysCreateTextNode: false
        };
        const promise = await apiGet<String>(`/campaigns`)
        const parser = new XMLParser(options);
        return parser.parse(promise).campaign
    }

    async copyCampaign(campaign: Campaign) {
        const options = {
            ignorePiTags: false,
            ignoreAttributes : false
        };


        campaign["@_status"] = "draft"
        campaign["@_id"] = uuidv4()
        campaign["@_name"] = campaign["@_name"]+" копия"
        const xml: xml = {
            campaign: campaign
        };
        const builder = new XMLBuilder(options);
        let xmlDataStr = builder.build(xml);
        return await apiPost('/campaigns', xmlDataStr)
    }

    async updateCampaign(campaign: Campaign) {
        const options = {
            ignorePiTags: false,
            ignoreAttributes : false
        };

        const xml: xml = {
            campaign: campaign
        };
        const builder = new XMLBuilder(options);
        let xmlDataStr = builder.build(xml);
        return await apiPut('/campaigns', xmlDataStr)
    }

    async deleteCampaign(campaign: Campaign) {
        return await apiDelete('/campaigns?id='+campaign["@_id"])
    }

    async activateCampaign(campaign: Campaign) {
        const options = {
            ignorePiTags: false,
            ignoreAttributes : false
        };
        const xml: xml = {
            campaign: campaign
        };
        const builder = new XMLBuilder(options);
        let xmlDataStr = builder.build(xml);
        return await apiPost('/campaigns/active',xmlDataStr)
    }

    async getRegions(): Promise<Map<any, Region>>{
        return await apiGet('/campaigns/regions')
    }

}

export const rotatorApi = new RotatorApi()