import repReportDark from "../assets/rep_report_dark.png";
import repReportLight from "../assets/rep_report_light.png";
import {Theme} from "@mui/material";


const icons = {
  repReport: {
    dark: repReportDark,
    light: repReportLight
  }
} as any

export function getThemedIcon(name: string, theme?: Theme): string | undefined {
  const iconRecord = icons[name]
  if (iconRecord) {
    if ((theme && theme.palette.mode === 'dark') ||
      (theme === undefined && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      return iconRecord.dark
    }
    return iconRecord.light
  }
}
