

export interface StateType {
    token: any,
    value1: string | null
    value2: number | null
}

export const initialState = {
    token: undefined,
    value1: null,
    value2: null
};

