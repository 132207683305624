import {StateType} from "./appState"


export enum ActionKind {
  LOAD_STATE,
  SET_TOKEN,
  CLEAR_TOKEN
}

export interface Action {
  type: ActionKind
  payload?: any
}

export function reducer(state: StateType, action: Action) {
  const {type, payload} = action;
  console.log(type, payload);
  switch (type) {
    case ActionKind.CLEAR_TOKEN:
      return {
        ...state,
        token: undefined
      }
    default:
      return {
        ...state,
        ...payload
      }
  }
}
