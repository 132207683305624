import Box from '@mui/material/Box'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {
  Button, CircularProgress,
  FormControl, Grid,
  InputLabel, Link,
  MenuItem, Paper,
  Select,
  SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Tooltip, Typography,
  useTheme
} from '@mui/material'
import {useEffect, useState} from "react"
import {DatePicker} from "@mui/x-date-pickers"
import {ProductKey, reportApi, SummaryReportEntry} from "../../api/report"
import {formatCurrency, formatMskDays} from "../../utils/format"
import {ApiRoot} from "../../api/common"
import Moment from "moment-timezone";



const validationSchema = yup.object({
  grantedFromLocal: yup.date()
    .required('Укажите начальную дату выдачи')
    .typeError('Укажите корректную дату'),
  grantedTillLocal: yup.date()
    .required('Укажите конечную дату выдачи')
    .typeError('Укажите корректную дату'),
  productKey: yup.string(),
  chosenPartner: yup.string(),
  repaidFromLocal: yup.date()
    .nullable()
    .typeError('Укажите корректную дату'),
  repaidTillLocal: yup.date()
    .nullable()
    .typeError('Укажите корректную дату')
})

interface Column {
  id: 'code' | 'pcLogin' | 'codeSourcePayment' | 'chosenPartner' | 'amountGranted' | 'amountRepaid';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {id: 'code', label: 'Код товара'},
  {id: 'pcLogin', label: 'pcLogin'},
  {id: 'codeSourcePayment', label: 'codeSourcePayment'},
  {id: 'chosenPartner', label: 'Партнёр'},
  {id: 'amountGranted', label: 'Выдано', align: 'right', format: formatCurrency},
  {id: 'amountRepaid', label: 'Погашено', align: 'right', format: formatCurrency}
]

export default function ReportRepayment() {
  const [formError, setFormError] = useState<string>();
  const [reportData, setReportData] = useState<SummaryReportEntry[]>();
  const [productKeys, setProductKeys] = useState<string[]>([]);
  const [partners, setPartners] = useState<string[]>([]);
  const [detailsQueryParams, setDetailsQueryParams] = useState<object>();

  const theme = useTheme()

  useEffect(() => {
    console.log('loading product keys')
    reportApi.productKeys().then(
      response => {
        setProductKeys(response.map((key: ProductKey) => `${key.code};${key.pcLogin};${key.codeSourcePayment}`))
      },
      error => {
        console.log('got error', error)
        setFormError('Произошла ошибка')
      }
    )
    reportApi.partners().then(
      response => {
        //console.log('partners', response)
        setPartners(response)
      },
      error => {
        console.log('got error', error)
        setFormError('Произошла ошибка')
      }
    )
  }, [])

  const now = new Date()
  const monthAgo = new Date()
  monthAgo.setMonth(now.getMonth() - 1)

  const formik = useFormik({
    initialValues: validationSchema.cast({
      grantedFromLocal: monthAgo,
      grantedTillLocal: now
    }),
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      //console.log('values', values)
      const
        {productKey, grantedFromLocal, grantedTillLocal, repaidFromLocal, repaidTillLocal, ...params} = values as any
      console.log('productKey', productKey)
      if (productKey) {
        const keyDetails = productKey.split(';')
        console.log('keyDetails', keyDetails)
        params.code = keyDetails[0]
        params.pcLogin = keyDetails[1]
        params.codeSourcePayment = keyDetails[2]
      }
      console.log(values)
      if (grantedFromLocal) params.grantedFrom = formatMskDays(grantedFromLocal)
      if (grantedTillLocal) params.grantedTill = formatMskDays(grantedTillLocal)
      if (repaidFromLocal) params.repaidFrom = formatMskDays(repaidFromLocal)
      if (repaidTillLocal) params.repaidTill = formatMskDays(repaidTillLocal)
      console.log('submit report params', JSON.stringify(params, null, 2), params)

      setFormError(undefined)
      reportApi.repaymentSummaryReport(params).then(
        response => {
          if (response && response.length > 0) {
            setDetailsQueryParams(params)
          }
          setReportData(response)
          formikHelpers.setSubmitting(false)
        },
        error => {
          console.log('got error', error)
          setFormError('Произошла ошибка')
          formikHelpers.setSubmitting(false)
        }
      )
    }
  })

  useEffect(() => {
    console.log('values changed')
    // console.log('from', formik.values.grantedFromLocal)
    // console.log('from ISO', formik.values.grantedFromLocal?.toISOString())
    if (detailsQueryParams) setDetailsQueryParams(undefined)
  }, [formik.values])

  const longerThanMonth =
    Moment(formik.values.grantedTillLocal).diff(Moment(formik.values.grantedFromLocal)) > 2678400000

  return (<form onSubmit={formik.handleSubmit}>
    <Grid container>
      <Grid item xs={12} sx={{px: 2}}>
        <Box sx={{color: theme.palette.error.main, minHeight: theme.typography.htmlFontSize * 1.5}}>
          {formError}
        </Box>
      </Grid>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <DatePicker
          label='Выдано с'
          value={formik.values.grantedFromLocal || null}
          onChange={(newValue) => {
            formik.setFieldValue('grantedFromLocal', newValue, true);
          }}
          renderInput={
            (params) =>
              <TextField
                {...params}
                fullWidth
                size='small'
                onChange={formik.handleChange}
                onFocus={() => formError && setFormError(undefined)}
                error={formik.touched.grantedFromLocal && Boolean(formik.errors.grantedFromLocal)}
                helperText={formik.touched.grantedFromLocal && formik.errors.grantedFromLocal as string}
              />
          }
        />
      </Grid>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <DatePicker
          label='Выдано по'
          value={formik.values.grantedTillLocal || null}
          onChange={(newValue) => {
            formik.setFieldValue('grantedTillLocal', newValue, true);
          }}
          renderInput={
            (params) =>
              <TextField
                {...params}
                fullWidth
                size='small'
                onChange={formik.handleChange}
                onFocus={() => formError && setFormError(undefined)}
                error={formik.touched.grantedTillLocal && Boolean(formik.errors.grantedTillLocal)}
                helperText={formik.touched.grantedTillLocal && formik.errors.grantedTillLocal as string}
              />
          }
        />
      </Grid>
      <Grid item xs={0} md={4}/>

      <Grid item xs={6} md={4} sx={{p: 1}}>
        <FormControl fullWidth size='small'>
          <InputLabel id="product-key-select-label">
            {productKeys.length === 0 ? <CircularProgress size={theme.typography.htmlFontSize * 1.5}/> : <>Код
              товара</>}
          </InputLabel>
          <Select
            labelId="product-key-select-label"
            id="product-key-select"
            value={formik.values.productKey || ''}
            label="Код товара"
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldValue('productKey', event.target.value);
            }}
            disabled={productKeys.length === 0}
          >
            <MenuItem value="">
              <em>Все</em>
            </MenuItem>
            {productKeys.map((key) => (
              <MenuItem
                key={key}
                value={key}
              >
                {key.replaceAll(';', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <FormControl fullWidth size='small'>
          <InputLabel id="partner-select-label">
            {partners.length === 0 ? <CircularProgress size={theme.typography.htmlFontSize * 1.5}/> : <>Партнёр</>}
          </InputLabel>
          <Select
            labelId="partner-select-label"
            id="partner-select"
            value={formik.values.chosenPartner || ''}
            label="Партнёр"
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldValue('chosenPartner', event.target.value);
            }}
            disabled={partners.length === 0}
          >
            <MenuItem value="">
              <em>Все</em>
            </MenuItem>
            {partners.map((partner) => (
              <MenuItem
                key={partner}
                value={partner}
              >
                {partner}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={0} md={4}/>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <DatePicker
          label='Погашения с'
          value={formik.values.repaidFromLocal || null}
          onChange={(newValue) => {
            formik.setFieldValue('repaidFromLocal', newValue);
          }}
          renderInput={
            (params) =>
              <TextField
                {...params}
                fullWidth
                size='small'
                onFocus={() => formError && setFormError(undefined)}
                error={formik.touched.repaidFromLocal && Boolean(formik.errors.repaidFromLocal)}
                helperText={formik.touched.repaidFromLocal && formik.errors.repaidFromLocal as string}
              />
          }
        />
      </Grid>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <DatePicker
          label='Погашения по'
          value={formik.values.repaidTillLocal || null}
          onChange={(newValue) => {
            formik.setFieldValue('repaidTillLocal', newValue);
          }}
          renderInput={
            (params) =>
              <TextField
                {...params}
                fullWidth
                size='small'
                onFocus={() => formError && setFormError(undefined)}
                error={formik.touched.repaidTillLocal && Boolean(formik.errors.repaidTillLocal)}
                helperText={formik.touched.repaidTillLocal && formik.errors.repaidTillLocal as string}
              />
          }
        />
      </Grid>
      <Grid item xs={0} md={4}/>

      <Grid item xs={6} md={4}/>
      <Grid item xs={6} md={4} sx={{p: 1}}>
        <Button type='submit' variant='outlined' disabled={formik.isSubmitting}>
          Отправить
        </Button>
      </Grid>
      <Grid item xs={0} md={4}/>

      {reportData && <>
        <Grid item xs={12} md={10} sx={{textAlign: 'right', pr: 3}}>
          <Box sx={{minHeight: theme.typography.htmlFontSize * 1.5}}>
            {detailsQueryParams && longerThanMonth && <Tooltip
              placement='top-end'
              title='Слишком длинный интервал. Детальный отчёт можно получить за период не длиннее, чем месяц'>
              <Typography sx={{textDecoration: 'underline'}}>Детальный отчёт (Excel)</Typography>
            </Tooltip>}
            {detailsQueryParams && !longerThanMonth && <Link href={ApiRoot + reportApi.repaymentDetailsUri(detailsQueryParams)}>
              Детальный отчёт (Excel)
            </Link>}
          </Box>
        </Grid>
        <Grid item xs={0} md={2}/>

        <Grid item xs={12} md={10}>
          <Paper sx={{overflow: 'hidden'}}>
            <TableContainer sx={{maxHeight: '60vh'}}>
              <Table stickyHeader aria-label="repayment summary table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{minWidth: column.minWidth}}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.map((row, index) =>
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<hr/><pre>{JSON.stringify(reportData, null, 2)}</pre>*/}
          </Paper>
        </Grid>
        <Grid item xs={0} md={2}/>

      </>}
    </Grid>
  </form>)
}
