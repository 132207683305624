import {Box, Grid, Link, useTheme} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {getThemedIcon} from "../../theme/icons";

export default function ReportDashboard() {
  const theme = useTheme()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Link component={RouterLink} to='repayment' underline='none' variant='subtitle2'>
          <Box sx={{
            width: 120,
            height: 120,
            backgroundImage: `url(${getThemedIcon('repReport', theme)})`,
            textAlign: 'center',
            display: 'table-cell',
            verticalAlign: 'middle'
          }}>
            Отчёт о погашении
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
}
