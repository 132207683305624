import Box from '@mui/material/Box'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {Button, TextField, Typography, useTheme} from '@mui/material'
import {otpApi} from "../../api/otp";
import {useState} from "react";
import {applyPhoneFormat, filterDigits} from "../../utils/format";


const validationSchema = yup.object({
  msisdn: yup
    .string()
    .required('Введите номер телефона')
    .min(5, 'Слишком короткий номер')
    .max(20, 'Слишком длинный номер')
})

export default function MsisdnForm({onSuccess}: { onSuccess: (msisdn: string) => void }) {

  const [formError, setFormError] = useState<string>();

  const theme = useTheme()

  const formik = useFormik({
    initialValues: {
      msisdn: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('submit msisdn', values)
      setFormError(undefined)
      const msisdn = filterDigits(values.msisdn)
      otpApi.init(msisdn).then(
        response => {
          if (response.state === 'ok') {
            onSuccess(msisdn)
          }
        },
        error => {
          console.log('got error', error)
          if (error instanceof Error && (error as Error).message.trim() === 'msisdn not allowed') {
            setFormError('Номер не авторизован')
          } else {
            setFormError('Произошла ошибка')
          }
        }
      )
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box height={theme.typography.htmlFontSize * 1.5} sx={{mb: 1}}>
        {formError && <Typography color={theme.palette.error.main}>{formError}</Typography>}
      </Box>
      <TextField
        variant='filled'
        fullWidth
        id='msisdn'
        name='msisdn'
        label='Номер телефона'
        value={formik.values.msisdn}
        onChange={event => {
          event.target.value = applyPhoneFormat(filterDigits(event.target.value))
          formik.handleChange(event)
        }}
        onFocus={() => formError && setFormError(undefined)}
        error={formik.touched.msisdn && Boolean(formik.errors.msisdn)}
        helperText={formik.touched.msisdn && formik.errors.msisdn}
      />
      <Button sx={{mt: 2}} fullWidth type='submit' variant='outlined'>
        Отправить
      </Button>
    </form>
  )
}
